import { faCheck, faDollar, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React, { useEffect, useRef, useState } from 'react';

import { FiltersProps } from './filters';
import './styles/jobBoard.css';

export default function FilterPay({ onFilter, status }: FiltersProps) {
  const [openMenu, setOpenMenu] = useState(false);
  const [checkedOptions, setCheckedOptions] = useState<string[]>([]);
  const menuRef = useRef<HTMLDivElement>(null);
  const submenuRef = useRef<HTMLDivElement>(null);
  const options = [
    '$0-$19 per hour',
    '$20-$29 per hour',
    '$30-$39 per hour',
    '$40-$49 per hour',
    '$50 and up or salaried',
  ];

  const handleClear = () => {
    setCheckedOptions([]);
    onFilter({ pay: [] });
  };

  useEffect(() => {
    handleClear();
  }, [status]);

  useEffect(() => {
    const handleDocumentClick = (e: MouseEvent) => {
      const menu = menuRef.current as HTMLElement | null;
      if (
        menu &&
        e.target &&
        !(e.target as Element).closest('.filter-dropdown.pay')
      ) {
        setOpenMenu(false);
      }
    };

    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  const handleCheck = (item: string) => {
    let checkedItems: string[] = checkedOptions;
    if (!checkedItems.includes(item)) {
      checkedItems.push(item);
    } else {
      checkedItems = checkedItems.filter((checkedItem) => checkedItem !== item);
    }
    setCheckedOptions(checkedItems);

    onFilter({ pay: checkedItems });
  };

  const toggleOpen = (e: React.MouseEvent<HTMLDivElement>) => {
    const menu = menuRef.current as HTMLElement | null;

    if (menu && e.target && !(e.target as Element).closest('.menuIcon')) {
      const menuPos = menu.getBoundingClientRect();

      if (menuPos && submenuRef.current) {
        submenuRef.current.style.left = menuPos.left + 'px';
      }
      setOpenMenu(!openMenu);
    }
  };

  return (
    <div className="filter-dropdown pay" ref={menuRef}>
      <div
        className={'filter-btn ' + (openMenu ? 'open' : '')}
        onClick={(e) => toggleOpen(e)}>
        <span className="filter-btn-title">
          <FontAwesomeIcon icon={faDollar} />
          {checkedOptions.length === 0
            ? 'Pay Range'
            : 'Pay Range (' + checkedOptions.length + ')'}
        </span>
        <FontAwesomeIcon
          icon={faXmark}
          className={
            'menuIcon ' + (checkedOptions.length !== 0 ? 'active' : '')
          }
          onClick={() => handleClear()}></FontAwesomeIcon>
      </div>
      <div
        className={'filter-items ' + (openMenu ? 'open' : '')}
        ref={submenuRef}>
        <ul className="filterOptions">
          {options.map((item, index) => (
            <li className="filter-item" key={index}>
              <input
                type="checkbox"
                id={`pay-${index}`}
                className="checkInput"
                value={item}
                checked={checkedOptions.includes(item)}
                onChange={() => handleCheck(item)}></input>
              <label htmlFor={`pay-${index}`} className="checkLabel">
                <div className="checkBox">
                  <FontAwesomeIcon icon={faCheck} className="checkIcon" />
                </div>
                <span>{item}</span>
              </label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
