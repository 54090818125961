import React, { useEffect, useState } from 'react';

import { Job } from '../model';
import StarComponent from './star';
import './styles/jobListing.css';

interface JobListingProps {
  job: Job;
  onStarClick?: (updatedJobs: string[]) => void | null;
}

// TODO: Move this to API folder for when we get a user API in the backend
function isJobFavorited(jobId: string): boolean {
  return JSON.parse(localStorage.getItem('savedJobs') || '[]').includes(jobId);
}

const JobListing: React.FC<JobListingProps> = ({ job, onStarClick }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isFavorited, setIsFavorited] = useState<boolean>(
    isJobFavorited(job.id),
  );

  useEffect(() => {
    setIsFavorited(isJobFavorited(job.id));
  }, [job.id]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleStarFill = (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>,
  ) => {
    event.stopPropagation();

    let savedJobs = JSON.parse(localStorage.getItem('savedJobs') || '[]');
    if (isFavorited) {
      savedJobs = savedJobs.filter((savedJob: string) => job.id !== savedJob);
    } else {
      savedJobs.push(job.id);
    }
    localStorage.setItem('savedJobs', JSON.stringify(savedJobs));
    setIsFavorited(!isFavorited);
    if (onStarClick != null) {
      onStarClick(savedJobs);
    }
  };

  const truncateText = (text: string, maxLength: number) => {
    return isExpanded || text.length <= maxLength
      ? text
      : text.substring(0, maxLength) + '...';
  };

  return (
    <div className="job-container">
      <div className="job-listing">
        <div
          className="title-container"
          onClick={toggleExpand}
          style={{ cursor: 'pointer' }}>
          <div className="title">
            <h3>{truncateText(job.title, 45)}</h3>
          </div>
          <div className="short-desc">
            <div className="location">
              <h4>{truncateText(job.location, 30)}</h4>
              <li>{truncateText(job.timeCommitment, 40)}</li>
            </div>
            <h5>{isExpanded ? 'Close Details' : 'View Details'}</h5>
          </div>
          <StarComponent
            isStarFilled={isFavorited}
            toggleStarFill={toggleStarFill}
          />
        </div>
        {isExpanded && (
          <div className="job-details">
            <div className="mobile-details">
              <p>
                <strong>Location:</strong> {truncateText(job.location, 40)}
              </p>
              <p>
                <strong>{job.timeCommitment}</strong>
              </p>
            </div>
            <p>
              <strong>Company:</strong> {job.company}
            </p>
            {job.typeOfWork && (
              <p>
                <strong>Work Environment:</strong> {job.typeOfWork}
              </p>
            )}
            {job.pay && (
              <p>
                <strong>Pay:</strong> {job.pay}
              </p>
            )}
            <strong>Description:</strong>
            <ul>
              {job.description.map((item, index) => (
                <li key={index}>
                  <strong>{item.title}:</strong> {item.content}
                  <br />
                  <br />
                </li>
              ))}
            </ul>
            <p>
              <a href={job.link} target="_blank" rel="noopener noreferrer">
                Link to Apply
              </a>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default JobListing;
