import {
  faCheck,
  faGraduationCap,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React, { useEffect, useRef, useState } from 'react';

import { FiltersProps } from './filters';
import './styles/jobBoard.css';

export default function FilterEducation({ onFilter, status }: FiltersProps) {
  const [openMenu, setOpenMenu] = useState(false);
  const [checkedOptions, setCheckedOptions] = useState<string[]>([]);
  const menuRef = useRef<HTMLDivElement>(null);
  const submenuRef = useRef<HTMLDivElement>(null);
  const options = [
    'High School/GED',
    "Associate's Degree",
    "Bachelor's Degree",
    "Master's/PhD Degree",
  ];

  const handleClear = () => {
    setCheckedOptions([]);
    onFilter({ education: [] });
  };

  useEffect(() => {
    handleClear();
  }, [status]);

  // General useEffect for handling clicks outside the dropdown
  useEffect(() => {
    const handleDocumentClick = (e: MouseEvent) => {
      const menu = menuRef.current as HTMLElement | null;
      if (
        menu &&
        e.target &&
        !(e.target as Element).closest('.filter-dropdown.education')
      ) {
        setOpenMenu(false);
      }
    };

    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  // logic for updating the list in jobBoard.tsx via updateList in services
  const handleCheck = (item: string) => {
    let checkedItems: string[] = checkedOptions;
    if (!checkedItems.includes(item)) {
      checkedItems.push(item);
    } else {
      const filteredItems = checkedItems.filter(
        (checkedItem) => checkedItem !== item,
      );
      checkedItems = filteredItems;
    }
    setCheckedOptions(checkedItems);

    if (checkedItems.length === 0) {
      onFilter({ education: [] });
      return;
    }
    const formattedItems: string[] = [];
    // format for High School/GED
    if (checkedItems.includes(options[0])) {
      formattedItems.push(
        'High School',
        'High school',
        'High School Diploma',
        'GED',
        'General Education Diploma',
      );
    }
    // format for Associate's Degree
    if (checkedItems.includes(options[1])) {
      formattedItems.push(
        'associates',
        'Associates',
        "associate's",
        "Associate's",
        'A.A',
        'A.S',
        '2-year',
        '2 year',
        'Community College',
        'CC',
      );
    }
    // format for Bachelor's Degree
    if (checkedItems.includes(options[2])) {
      formattedItems.push(
        'bachelors',
        'Bachelors',
        "bachelor's",
        "Bachelor's",
        'B.A',
        'B.S',
        '4-year',
        '4 year',
        'Undergraduate',
        'undergraduate',
        'majoring',
        'Freshman',
        'Sophomore',
        'Junior',
        'Senior',
      );
    }
    // format for Masters/PhD Degree
    if (checkedItems.includes(options[3])) {
      formattedItems.push(
        'Masters',
        "Master's",
        'M.S',
        'MBA',
        'M.B.A',
        'PhD',
        'Ph.D',
        'Doctoral',
        'Doctorate',
      );
    }

    onFilter({ education: formattedItems });
  };

  const toggleOpen = (e: React.MouseEvent<HTMLDivElement>) => {
    const menu = menuRef.current as HTMLElement | null;

    if (menu && e.target && !(e.target as Element).closest('.menuIcon')) {
      const menuPos = menu.getBoundingClientRect();

      if (menuPos && submenuRef.current) {
        submenuRef.current.style.left = menuPos.left + 'px';
      }
      setOpenMenu(!openMenu);
    }
  };

  return (
    <div className="filter-dropdown education" ref={menuRef}>
      <div
        className={'filter-btn ' + (openMenu ? 'open' : '')}
        onClick={(e) => toggleOpen(e)}>
        <span className="filter-btn-title">
          <FontAwesomeIcon icon={faGraduationCap} />
          {checkedOptions.length === 0
            ? 'Education'
            : 'Education (' + checkedOptions.length + ')'}
        </span>
        <FontAwesomeIcon
          icon={faXmark}
          className={
            'menuIcon ' + (checkedOptions.length !== 0 ? 'active' : '')
          }
          onClick={() => handleClear()}></FontAwesomeIcon>
      </div>
      <div
        className={'filter-items ' + (openMenu ? 'open' : '')}
        ref={submenuRef}>
        <div className="filtersChecked"></div>
        <ul className="filterOptions">
          {options.map((item, index) => (
            <li className="filter-item" key={index}>
              <input
                type="checkbox"
                id={`education=${index.toString()}`}
                className="checkInput"
                value={item}
                checked={checkedOptions.includes(item) ? true : false}
                onChange={() => handleCheck(item)}></input>
              <label
                htmlFor={`education=${index.toString()}`}
                className="checkLabel">
                <div className="checkBox">
                  <FontAwesomeIcon icon={faCheck} className="checkIcon" />
                </div>
                <span>{item}</span>
              </label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
