// import { GoogleAuthProvider, signInWithPopup } from '@firebase/auth';
import React from 'react';
import { useState } from 'react';

// import { auth } from '../firebaseConfig';
// Import the missing dependency
// import { LoggerContext } from '../services/logger-context.service';
import { useAuth } from '../util/authContext';
// import { useSafeContext } from '../util/useSafeContext';
import './styles/login.css';

// const { user, signInWithGoogle, signOut } = useAuth();
// const logger = useSafeContext(LoggerContext);
// const handleSignOut = async () => {
//   try {
//     await signOut();
//     logger.log('Signed out successfully');
//   } catch (error) {
//     logger.error('sign_out_error', error);
//   }
// };

const Login: React.FC = () => {
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  // const handleSignIn = async () => {
  //   const provider = await new GoogleAuthProvider();
  //   return signInWithPopup(auth, provider);
  // };

  const { enhancedLogin } = useAuth();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const error = enhancedLogin(password);
    if (error) {
      setErrorMessage(error);
    }
  };

  return (
    <div className="mainWrapper">
      <div className="loginLogo">
        <img src="/logo.png" alt="logo" />
      </div>
      <div className="btnContainer">
        {/* {user ? (
          <button className="btn" onClick={handleSignOut}>
            Sign Out
          </button>
        ) : (
          <button className="btn" onClick={handleSignIn}>
            Login with Google
          </button>
        )} */}
        {/* <button className="btn" onClick={handleSignIn}>
          Login with Google
        </button> */}
        <form onSubmit={handleSubmit}>
          <input
            type="password"
            placeholder="password"
            name="password"
            value={password}
            onChange={handlePasswordChange}
          />
          <button className="login" type="submit">
            Login
          </button>
        </form>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      </div>
      <div className="titleContainer">
        <h2 className="title1">Welcome to Digital Nest</h2>
        <h1 className="title2">Career Harvest</h1>
        <img className="person" src="/person 1.png" alt="person" />
      </div>
    </div>
  );
};

export default Login;
