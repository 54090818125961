import React, { useState } from 'react';

import FilterEducation from './filter-education';
import FilterJobCategory from './filter-job-category';
import FilterLocation from './filter-location';
import FilterPay from './filter-pay';
import FilterTypes from './filter-types';
import './styles/jobBoard.css';

export interface FiltersProps {
  onFilter: (filters: { [key: string]: string[] }) => void;
  status: boolean;
}

interface FilterParentProps {
  onFilter: (filters: { [key: string]: string[] }) => void;
  onClearFilters: () => void;
}

export default function Filters({
  onFilter,
  onClearFilters,
}: FilterParentProps) {
  const [resetStatus, setResetStatus] = useState<boolean>(false);
  const filterProps: FiltersProps = {
    onFilter,
    status: resetStatus,
  };

  return (
    <div className="filtersContainer">
      {/* Filter components goes here corresponding to each div */}
      <FilterLocation {...filterProps} />
      <FilterJobCategory {...filterProps} />
      <FilterTypes {...filterProps} />
      <FilterEducation {...filterProps} />
      <FilterPay {...filterProps} />
      {/* Clear filters btn */}
      <div
        className="filter-btn reset"
        onClick={() => {
          setResetStatus((prev) => !prev);
          onClearFilters();
        }}>
        Clear Filters
      </div>
    </div>
  );
}
