export const formatContent = (contentArray: string[]): string => {
  return contentArray
    .map((content, index) => {
      if (content.endsWith('.') || index === contentArray.length - 1) {
        return content;
      } else {
        return `${content},`;
      }
    })
    .join(' ');
};
