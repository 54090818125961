import React, { useState } from 'react';

import JobBoard from './jobBoard';
import Saved from './saved';
import Sidebar from './sidebar';
import './styles/main.css';

export interface SidebarProps {
  currPage: string;
  setCurrPage: React.Dispatch<React.SetStateAction<string>>;
}

export default function Main() {
  const [currPage, setCurrPage] = useState<string>('dashboard');

  const renderPage = () => {
    switch (currPage) {
      case 'dashboard':
        return <JobBoard />;
      case 'saved':
        return <Saved />;
      default:
        return <JobBoard />;
    }
  };

  const sidebarProps: SidebarProps = {
    currPage,
    setCurrPage,
  };

  return (
    <main className="main">
      <Sidebar {...sidebarProps} />
      {renderPage()}
    </main>
  );
}
