import React from 'react';

import SearchComponent from './search-jobs.component';
import './styles/jobBoard.css';

export default function JobBoard() {
  return (
    <section className="container">
      <div className="jobsContainer">
        <SearchComponent />
      </div>
    </section>
  );
}
