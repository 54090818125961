import React, { ReactNode, createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface AuthContextType {
  isAuthenticated: boolean;
  login: (password: string) => string | undefined;
  logout: () => void;
  enhancedLogin: (password: string) => string | undefined;
  enhancedLogout: () => void;
}

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined,
);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  const login = (password: string): string | undefined => {
    const correctPassword = process.env.REACT_APP_PASSWORD; // Set your simple password here
    if (password === correctPassword) {
      setIsAuthenticated(true);
      return undefined;
    } else {
      return 'Incorrect password. Please try again.';
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
  };

  const enhancedLogin = (password: string) => {
    const error = login(password);
    if (!error) {
      navigate('/');
    }
    return error;
  };

  const enhancedLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, login, logout, enhancedLogin, enhancedLogout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
