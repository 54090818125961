import { useQuery } from '@tanstack/react-query';

import React, { createContext } from 'react';

import { fetchJobs } from '../api/jobs/service';
import { Job } from '../model';

interface JobsContextInterface {
  jobs: Job[];
  isLoading: boolean;
  isError: boolean;
}

export const JobsContext = createContext<JobsContextInterface | undefined>(
  undefined,
);

interface JobsContextProviderProps {
  children: React.ReactNode;
}

export const JobsContextProvider: React.FC<JobsContextProviderProps> = ({
  children,
}) => {
  const {
    data: jobs = [],
    isLoading,
    isError,
  } = useQuery<Job[], Error>({
    queryKey: ['jobs'],
    queryFn: fetchJobs,
  });

  return (
    <JobsContext.Provider value={{ jobs, isLoading, isError }}>
      {children}
    </JobsContext.Provider>
  );
};
