import { faCheck, faList, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React, { useEffect, useRef, useState } from 'react';

import { JobsContext } from '../services/jobs-context.service';
import { useSafeContext } from '../util/useSafeContext';
import { FiltersProps } from './filters';
import './styles/jobBoard.css';

export default function FilterJobCategory({ onFilter, status }: FiltersProps) {
  const { jobs } = useSafeContext(JobsContext);
  const [openMenu, setOpenMenu] = useState(false);
  const [jobCategories, setJobCategories] = useState<string[]>([]);
  const [checkedOptions, setCheckedOptions] = useState<string[]>([]);
  const menuRef = useRef<HTMLDivElement>(null);
  const submenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const jobCategoriesSet = new Set<string>();
    jobs.forEach((job) => {
      jobCategoriesSet.add(job.jobCategory);
    });
    setJobCategories(Array.from(jobCategoriesSet));
  }, [jobs]);

  const handleClear = () => {
    setCheckedOptions([]);
    onFilter({ jobCategory: [] });
  };

  useEffect(() => {
    handleClear();
  }, [status]);

  // General useEffect for handling clicks outside the dropdown
  useEffect(() => {
    const handleDocumentClick = (e: MouseEvent) => {
      const menu = menuRef.current as HTMLElement | null;
      if (
        menu &&
        e.target &&
        !(e.target as Element).closest('.filter-dropdown.categories')
      ) {
        setOpenMenu(false);
      }
    };

    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  // logic for updating the list in jobBoard.tsx via updateList in services
  const handleCheck = (item: string) => {
    let checkedItems: string[] = checkedOptions;
    if (!checkedItems.includes(item)) {
      checkedItems.push(item);
    } else {
      const filteredItems = checkedItems.filter(
        (checkedItem) => checkedItem !== item,
      );
      checkedItems = filteredItems;
    }
    setCheckedOptions(checkedItems);

    if (checkedItems.length === 0) {
      onFilter({ jobCategory: [] });
      return;
    }

    onFilter({ jobCategory: checkedItems });
  };

  const toggleOpen = (e: React.MouseEvent<HTMLDivElement>) => {
    const menu = menuRef.current as HTMLElement | null;

    if (menu && e.target && !(e.target as Element).closest('.menuIcon')) {
      const menuPos = menu.getBoundingClientRect();

      if (menuPos && submenuRef.current) {
        submenuRef.current.style.left = menuPos.left + 'px';
      }
      setOpenMenu(!openMenu);
    }
  };

  return (
    <div className="filter-dropdown categories" ref={menuRef}>
      <div
        className={'filter-btn ' + (openMenu ? 'open' : '')}
        onClick={(e) => toggleOpen(e)}>
        <span className="filter-btn-title">
          <FontAwesomeIcon icon={faList} />
          {checkedOptions.length === 0
            ? 'Job Category'
            : 'Job Category (' + checkedOptions.length + ')'}
        </span>
        <FontAwesomeIcon
          icon={faXmark}
          className={
            'menuIcon ' + (checkedOptions.length !== 0 ? 'active' : '')
          }
          onClick={() => handleClear()}></FontAwesomeIcon>
      </div>
      <div
        className={'filter-items ' + (openMenu ? 'open' : '')}
        ref={submenuRef}>
        <ul className="filterOptions">
          {jobCategories.length === 0 ? (
            <li className="italicized">no options available...</li>
          ) : (
            jobCategories.map((item, index) => (
              <li className="filter-item" key={index}>
                <input
                  type="checkbox"
                  id={`jobCategory=${index.toString()}`}
                  className="checkInput"
                  value={item}
                  checked={checkedOptions.includes(item) ? true : false}
                  onChange={() => handleCheck(item)}></input>
                <label
                  htmlFor={`jobCategory=${index.toString()}`}
                  className="checkLabel">
                  <div className="checkBox">
                    <FontAwesomeIcon icon={faCheck} className="checkIcon" />
                  </div>
                  <span>{item}</span>
                </label>
              </li>
            ))
          )}
        </ul>
      </div>
    </div>
  );
}
