import React, { useEffect, useState } from 'react';

import { Job } from '../model';
import { JobsContext } from '../services/jobs-context.service';
import { useSafeContext } from '../util/useSafeContext';
import JobListing from './jobListing';
import Pagination from './pagination.component';
import './styles/saved.css';

export default function Saved() {
  const { jobs } = useSafeContext(JobsContext);
  const [savedJobs, setSavedJobs] = useState<Job[]>([]);

  useEffect(() => {
    const storedJobs = JSON.parse(localStorage.getItem('savedJobs') || '[]');
    updateSavedJobs(storedJobs);
  }, [jobs]);

  const updateSavedJobs = (updatedJobs: string[]) => {
    setSavedJobs(jobs.filter((job) => updatedJobs.includes(job.id)));
  };
  const [scrolled, setScrolled] = useState(false);

  return (
    <section className="savedContainer">
      <h1 className="savedJobsHeader">Saved Listings</h1>
      <div className="starredList">
        <Pagination
          queryResults={savedJobs}
          renderItem={(job: Job, index: number) => (
            <JobListing
              key={index}
              job={job}
              onStarClick={(updatedJobs: string[]) =>
                updateSavedJobs(updatedJobs)
              }
            />
          )}
          onScroll={(ref) => {
            if (ref.current) {
              setScrolled(ref.current.scrollTop > 50);
            }
          }}
          scrolled={scrolled}
        />
      </div>
    </section>
  );
}
