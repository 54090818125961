import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import AppRoutes from './routes';
import { LoggerProvider } from './services/logger-context.service';
import { AuthProvider } from './util/authContext';

const queryClient = new QueryClient({});

function App() {
  return (
    <LoggerProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </LoggerProvider>
  );
}

export default App;
