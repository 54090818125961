export default function debounce(fn: () => void, delay = 5000) {
  let timeout: NodeJS.Timeout | null = null;
  return () => {
    if (timeout !== null) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      fn();
    }, delay);
  };
}
