import {
  faBars,
  faCircleCheck,
  faGaugeHigh,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React, { useState } from 'react';

import { SidebarProps } from './main';
import './styles/sidebar.css';

export default function Sidebar({ currPage, setCurrPage }: SidebarProps) {
  const [openMenu, setOpenMenu] = useState(true);

  return (
    <section className={'sidebarParent ' + (!openMenu ? 'thin' : '')}>
      <div className="toggleMenuParent">
        <FontAwesomeIcon
          icon={faBars}
          className="toggleMenu"
          onClick={() => setOpenMenu(!openMenu)}
        />
        <div className="tooltip">Toggle Menu</div>
      </div>
      <div className="primaryTabs">
        <div
          className={
            'primary dashboard ' + (currPage === 'dashboard' ? 'active' : '')
          }
          onClick={() => setCurrPage('dashboard')}>
          <FontAwesomeIcon icon={faGaugeHigh} />
          <span className="tabTitle">Dashboard</span>
        </div>
        <div
          className={'primary saved ' + (currPage === 'saved' ? 'active' : '')}
          onClick={() => setCurrPage('saved')}>
          <FontAwesomeIcon icon={faCircleCheck} />
          <span className="tabTitle">Saved</span>
        </div>
        <hr className="line" />
      </div>
    </section>
  );
}
