import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Footer from './components/footer';
import Header from './components/header';
import Main from './components/main';
import Login from './pages/login';
import { JobsContextProvider } from './services/jobs-context.service';
import { AuthProvider } from './util/authContext';
import ProtectedRoute from './util/protectedRoute';

const AppRoutes: React.FC = () => (
  <AuthProvider>
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        path="/"
        element={
          <ProtectedRoute
            element={
              <JobsContextProvider>
                <Header />
                <Main />
                <Footer />
              </JobsContextProvider>
            }
          />
        }
      />
    </Routes>
  </AuthProvider>
);

export default AppRoutes;
