import React from 'react';

import { useAuth } from '../util/authContext';
import './styles/header.css';

export default function Header() {
  const { enhancedLogout } = useAuth();

  return (
    <header>
      <img src="/logo.png" alt="logo" />
      <h1>Career Harvest</h1>
      <button className="logout" onClick={enhancedLogout}>
        Logout
      </button>
    </header>
  );
}
