import { Description, Job } from '../../model';
import { formatContent } from '../../util/formatContent';

export const fetchJobs = async (): Promise<Job[]> => {
  const apiUrl = process.env.REACT_APP_API_URL + '/api/internships';

  if (!apiUrl) {
    console.error('API URL is not defined');
  }

  const response = await fetch(apiUrl);
  if (!response.ok) {
    console.error('Network error:', response.statusText);
  }
  const data = await response.json();
  return (
    data
      // .filter ((job: Job) => job.display === "display") // Uncomment this line to filter out jobs that are set to "no display"
      .map(
        (job: Job): Job => ({
          display: job.display,
          title: job.title,
          company: job.company,
          location: job.location,
          pay: job.pay,
          description: job.description.map(
            (item: Description): Description => ({
              title: item.title,
              // Process the content to ensure proper formatting
              content: Array.isArray(item.content)
                ? formatContent(item.content)
                : item.content,
            }),
          ),
          typeOfWork: job.typeOfWork,
          timeCommitment: job.timeCommitment,
          link: job.link,
          id: job.id, // TODO: have a uuid in the backend instead of the link
          jobCategory: job.jobCategory,
        }),
      )
  );
};
